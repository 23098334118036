import React from 'react';
import { Layout, Typography } from 'antd';
import { StopOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Text } = Typography;

const commonEmails = [
	'juan.benavides@gibobs.com',
	'javier.canadilla@gibobs.com',
	'jose.hernandez@gibobs.com',
	'pablo.cabanyes@gibobs.com',
	'miriam.torrens@gibobs.com',
	'yanina.aguirre@gibobs.com',
	'gonzalo.concepcion@gibobs.com',
	'manuel.miranda@gibobs.com',
	'sergio.martin@gibobs.com',
	'silvia.varela@gibobs.com',
	'belen.gonzalez@gibobs.com',
	'pau.costa@gibobs.com',
	'mireia.martin@gibobs.com',
	'jimmy.figueroa@gibobs.com',
	'samuel.ferrer@gibobs.com'
];

type CyclopsForbiddenProps = {
	validEmails?: string[];
	validRoles?: string[];
	children: React.JSX.Element | React.JSX.Element[] | (() => React.JSX.Element) | React.ReactNode;
	profile: CyclopsAuthProfile;
};

type CyclopsAuthProfile = {
	email: string;
	type: string;
};

export const CyclopsForbidden = ({
	validEmails = [],
	children,
	validRoles = [],
	profile
}: CyclopsForbiddenProps) => {
	const { email, type } = profile;

	const isValidUser = commonEmails.concat(validEmails).indexOf(email) !== -1;
	const isValidRole = validRoles && validRoles.indexOf(type) !== -1;

	let content: React.ReactNode;

	if (isValidUser || isValidRole) {
		content = children as React.ReactNode;
	} else {
		content = (
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					marginTop: 250,
					height: '80vh'
				}}>
				<StopOutlined style={{ fontSize: 48, color: '#02C3CD' }} />
				<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 400, marginTop: 32 }}>
					Lo sentimos,
				</Text>
				<Text style={{ color: '#02C3CD', fontSize: 20, fontWeight: 700 }}>
					no tienes permisos para acceder a este menú.
				</Text>
			</div>
		);
	}

	return (
		<Layout style={{ background: 'white', height: '100%' }}>
			<Content style={{ background: 'white' }}>{content}</Content>
		</Layout>
	);
};
