import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Document } from './DocumentEntity';
import { Buffer } from 'buffer';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { PDFDocument, PDFImage } from '@cantoo/pdf-lib';
import { notification } from 'antd';

export type DocumentMergeList = {
	newName: string;
	documentsToMerge: Document[];
};

export type StructuredDocument = {
	name: string;
	folders: StructuredDocument[];
	contents: Array<DocumentMergeList | Document>;
};

export async function buildAndSaveRecomendadorPdf(rootDirectory: StructuredDocument) {
	const zip = new JSZip();
	await buildFolders(rootDirectory, zip);

	const zipBase64 = await zip.generateAsync({ type: 'blob' });
	saveAs(zipBase64, rootDirectory.name);
}

async function buildFolders(directory: StructuredDocument, zip: JSZip) {
	const currentDirectory = zip.folder(directory.name);

	// Remove null or undefined elements.
	directory.contents = directory.contents.filter((element) => {
		return !(element === null || element === undefined);
	});

	// Build files for the current directory
	for await (const element of directory.contents) {
		//Ducktyping of the contents to differentiate between a document and a documentMergeList
		if ((element as DocumentMergeList).newName !== undefined) {
			await mergeDocuments(element as DocumentMergeList, currentDirectory);
		} else {
			await downloadDocumentToDirectory(element as Document, currentDirectory);
		}
	}

	// Build directories with the root in this one
	for await (const folder of directory.folders) {
		if (folder) {
			await buildFolders(folder, currentDirectory);
		}
	}
}

async function downloadDocumentToDirectory(element: Document, currentDirectory: JSZip) {
	const document = element as Document;
	const fileData = await getDocumentDataFromS3(document);
	currentDirectory.file(`${document.name}.${document.extension}`, fileData);
}

async function getDocumentDataFromS3(document: Document): Promise<Buffer> {
	const token = authClient.getToken();
	const response = await axios.get(`${env.api.url}/v1/documents/get-document-url/${document.id}`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	const s3Response = await axios.get(response.data.data.url, { responseType: 'arraybuffer' });

	return Buffer.from(s3Response.data, 'binary');
}

async function mergeDocuments(mergeList: DocumentMergeList, zip: JSZip) {
	// If we find any document that we cannot merge, we dump everything into a folder
	const supportedImageExtensions = ['png', 'jpeg', 'jpg'];
	const supportedExtensions = [...supportedImageExtensions, 'pdf'];
	let dumpToFolder = false;
	for (const document of mergeList.documentsToMerge) {
		if (!supportedExtensions.includes(document.extension)) {
			dumpToFolder = true;
		}
	}
	if (dumpToFolder) {
		// Create a folder with the newName
		const dumpDirectory = zip.folder(mergeList.newName);
		// Dump everything into it
		for await (const document of mergeList.documentsToMerge) {
			await downloadDocumentToDirectory(document, dumpDirectory);
		}
		// We are done here.
		return;
	}

	// Proceed with merging everything
	// Sort everything by pagination order
	mergeList.documentsToMerge.sort((a, b) => parseInt(a.page) - parseInt(b.page));

	const mergedPDF = await PDFDocument.create();
	// Handle JPG and PNG
	const imageDocuments = mergeList.documentsToMerge.filter((value) => {
		return supportedImageExtensions.includes(value.extension);
	});
	for await (const imageDocument of imageDocuments) {
		await handleAddingImage(imageDocument, mergedPDF);
	}
	// Merge PDFs
	const pdfDocuments = mergeList.documentsToMerge.filter((value) => {
		return value.extension == 'pdf';
	});
	for await (const pdfDocument of pdfDocuments) {
		await handleAddingPdf(pdfDocument, mergedPDF);
	}

	const pdfBytes = await mergedPDF.save();
	zip.file(`${mergeList.newName}.pdf`, pdfBytes);
}
async function handleAddingImage(imageDocument: Document, mergedPDF: PDFDocument) {
	const imageData = await getDocumentDataFromS3(imageDocument);
	// Create a new page for each image
	const imagePage = mergedPDF.addPage();

	// Embed the image in the PDF
	let embeddedHandle: PDFImage;
	switch (imageDocument.extension) {
		case 'png':
			embeddedHandle = await mergedPDF.embedPng(imageData);
			break;
		default:
			embeddedHandle = await mergedPDF.embedJpg(imageData);
			break;
	}

	// Get the page and image dimensions
	const pageWidth = imagePage.getWidth();
	const pageHeight = imagePage.getHeight();
	const imageWidth = embeddedHandle.width;
	const imageHeight = embeddedHandle.height;

	// Calculate the scale ratios based on the image and page size
	const widthRatio = pageWidth / imageWidth;
	const heightRatio = pageHeight / imageHeight;

	// Choose the smaller scale factor to ensure the image fits within the page
	const scaleFactor = Math.min(widthRatio, heightRatio);

	// Calculate the scaled dimensions of the image
	const scaledWidth = imageWidth * scaleFactor;
	const scaledHeight = imageHeight * scaleFactor;

	// Calculate the X and Y positions to center the image on the page
	const xPosition = (pageWidth - scaledWidth) / 2;
	const yPosition = (pageHeight - scaledHeight) / 2;

	// Draw the image centered on the page
	imagePage.drawImage(embeddedHandle, {
		x: xPosition,
		y: yPosition,
		width: scaledWidth,
		height: scaledHeight
	});
}

async function normalizePdf(pdfData) {
	try {
		const pdfDoc = await PDFDocument.load(pdfData, { ignoreEncryption: true });
		console.log('PDF cargado correctamente para normalización.');
		const normalizedPdfBytes = await pdfDoc.save();
		console.log('PDF regenerado y normalizado.');
		return normalizedPdfBytes;
	} catch (error) {
		console.error('Error normalizando el PDF:', error);
		throw error;
	}
}

async function handleAddingPdf(pdfDocument, mergedPDF) {
	try {
		// Descargar los datos del PDF desde S3
		const pdfData = await getDocumentDataFromS3(pdfDocument);

		// Convertir a Buffer si es necesario
		const pdfDataBuffer = Buffer.from(pdfData);
		const normalizedPdfBuffer = await normalizePdf(pdfDataBuffer);

		// Intentar cargar el PDF
		let pdfDoc = await PDFDocument.load(normalizedPdfBuffer, { ignoreEncryption: true });

		// Guardar y recargar para normalizar
		const normalizedPdfBytes = await pdfDoc.save();
		pdfDoc = await PDFDocument.load(normalizedPdfBytes);

		// Validar si tiene páginas antes de intentar copiarlas
		if (pdfDoc.getPageCount() === 0) {
			throw new Error('El archivo PDF no tiene páginas.');
		}

		// Copiar páginas y agregarlas al PDF fusionado
		const pages = await mergedPDF.copyPages(pdfDoc, pdfDoc.getPageIndices());
		pages.forEach((page) => mergedPDF.addPage(page));
	} catch (error) {
		notification.error({
			duration: 0,
			message: `Error procesando el PDF: ${pdfDocument.name}`,
			description:
				'Tienes que descargar el documento de forma individual y guardarlo en la carpeta que corresponda.'
		});
		console.error(error);
	}
}
