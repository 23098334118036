import React, { useEffect } from 'react';
import {
	AndroidOutlined,
	BankOutlined,
	BarChartOutlined,
	FileAddOutlined,
	HistoryOutlined,
	HomeOutlined,
	LoginOutlined,
	MonitorOutlined,
	ProfileOutlined,
	ScheduleOutlined,
	ShopOutlined,
	UserAddOutlined,
	UserSwitchOutlined,
	TranslationOutlined,
	DeploymentUnitOutlined,
	FileOutlined,
	CrownOutlined
} from '@ant-design/icons';

import authClient from '../../Auth/Auth';

const SidebarItemsHadminMenu = (profileType) => {
	return [
		{
			name: 'Inicio',
			route: '/',
			icon: <HomeOutlined />,
			id: 'init'
		},
		{
			name: 'Gestor de tareas',
			route: '/management',
			icon: <ScheduleOutlined />,
			id: 'management'
		},
		{
			name: 'Operaciones',
			route:
				profileType === 'php-risk'
					? '/promoter-loan/list/active'
					: '/operations/mortgages/list/active',
			icon: <ScheduleOutlined />,
			id: 'operations'
		},
		{
			name: 'RECO’s',
			route: '/recos/pending',
			icon: <CrownOutlined />,
			id: 'recos'
		},
		{
			name: 'Preregistros',
			route: '/preregister',
			icon: <UserSwitchOutlined />,
			id: 'preregister'
		},
		{
			name: 'Agencias B2B',
			route: '/realestates/realestates',
			icon: <ShopOutlined />,
			id: 'realestates'
		},
		{
			name: 'Entidades bancarias',
			route: '/banks',
			icon: <ShopOutlined />,
			id: 'banks'
		},

		{
			name: 'Informes',
			route: '/dashboardDetails',
			icon: <BarChartOutlined />,
			id: 'dashboardDetails'
		},
		{
			name: 'Herramientas',
			route: '/tools/blueprints',
			icon: <ShopOutlined />,
			id: 'tools'
		},
		{
			name: 'Área de gestión',
			route: '/management-area',
			icon: <FileOutlined />,
			id: 'managementArea'
		}
	];
};

/*
if(auth.getProfile().type === "admin") {
	SidebarItemsHadminMenu.push({
		name: "Blueprints",
		route: '/tools/blueprints',
		icon: <DeploymentUnitOutlined />
	});
}

SidebarItemsHadminMenu.push({
	name: "Afterbanks",
	route: '/tools/afterbanks',
	icon: <BankOutlined />
});
*/

export const SidebarItemsHadmin = SidebarItemsHadminMenu;

export const SidebarItemsCustomers = [
	{
		name: 'Inicio',
		route: '/home',
		icon: <HomeOutlined />
	}
];

export const SidebarItemsLoginCustomer = [
	{
		name: 'Login',
		route: '/login',
		icon: <UserAddOutlined />
	},
	{
		name: 'signUp',
		route: '/signUp',
		icon: <LoginOutlined />
	},
	{
		name: 'Olvide Contraseña',
		route: '/reset-password',
		icon: <AndroidOutlined />
	},
	{
		name: 'Olvide mi usuario',
		route: '/reset-user'
	}
];

export const SidebarItemsLoginHadmmin = [
	{
		name: 'Login',
		route: '/login',
		icon: <UserAddOutlined />
	},
	{
		name: 'Verificar',
		route: '/verify',
		icon: <MonitorOutlined />
	},
	{
		name: 'Propiedades',
		route: '/properties',
		icon: <BankOutlined />
	},
	{
		name: 'Comercial',
		route: '/sales',
		icon: <ProfileOutlined />
	}
];
