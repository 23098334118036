import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	Select,
	Tabs,
	Typography
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { hidewModalRedux, updateStateMortgages } from '../../store/mortgages/actions';
import moment from 'moment';
import axios from 'axios';
import env from '../../environment';
import authClient from '../../Auth/Auth';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
const { Option } = Select;
const { Text } = Typography;

const SnoozeModal = (props) => {
	const dispatch = useDispatch();
	const { mortgageId, promoter } = props;
	const showModal = useSelector((state) => state.mortgages.showModal);
	const [enable2, setEnable2] = useState(false);
	const [selectmotivo1, setSelectmotivo1] = useState('option1');
	const [fecha1, setFecha1] = useState(new Date().toLocaleString());
	const [notas, setNotas] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const [banksList, setBanksList] = useState(undefined);
	const { TabPane } = Tabs;
	const optionsPromoter = [
		{ label: 'Pendiente envío documentación', value: 'Pendiente envío documentación', id: '0' },
		{ label: 'Pendiente compra suelo', value: 'Pendiente compra suelo', id: '1' }
	];

	const optionsNonPromoter = [
		{ label: 'Entrega de promoción tardía', value: 'Entrega de promoción tardía', id: '0' },
		{ label: 'Lo va a mirar con otro banco', value: 'Lo va a mirar con otro banco', id: '1' },
		{
			label: 'Necesita vender su vivienda previamente',
			value: 'Necesita vender su vivienda previamente',
			id: '2'
		},
		{ label: 'Está buscando vivienda', value: 'Está buscando vivienda', id: '3' },
		{
			label: 'Situación laboral en proceso de cambio',
			value: 'Situación laboral en proceso de cambio',
			id: '4'
		},
		{
			label: 'Esperando reconocimiento. YA FIRMADA',
			value: 'Esperando reconocimiento. YA FIRMADA',
			id: '5'
		},
		{
			label: 'Esperando reconocimiento. PTE DE ASIGNACIÓN',
			value: 'Esperando reconocimiento. PTE DE ASIGNACIÓN',
			id: '6'
		},
		{
			label: 'Pendiente bajada de tipos',
			value: 'Pendiente bajada de tipos',
			id: '7'
		},
		{
			label: 'Otro',
			value: 'Otro',
			id: '8'
		}
	];

	useEffect(() => {
		getBanksList();
	}, []);

	const getBanksList = () => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data.sort(function (a, b) {
					if (a.bankCode > b.bankCode) {
						return 1;
					}
					if (a.bankCode < b.bankCode) {
						return -1;
					}
					return 0;
				});
				setBanksList(response.data.data.filter((e) => e.bankCode !== null));
			});
	};

	const handleApiPostpone = async (bodyHttp) => {
		const { body, reason, subreason, type, date, closeAllTask } = bodyHttp;
		let axiosConfig = {
			headers: {
				Authorization: `Bearer ${authClient.getToken()}`
			}
		};

		axios
			.post(
				`${env.api.url}/api/hadmin/mortgage-reminder`,
				{ mortgageId, body, reason, subreason, type, date, closeAllTask },
				axiosConfig
			)
			.then((res) => {
				dispatch(updateStateMortgages(!mortgageUpda));
				handleOk();
				setIsDisabled(false);
				message.success('Su snooze se creo correctamente.');
			})
			.catch((err) => {
				console.log('SnoozeModal ERROR: ', err);
				setIsDisabled(false);
			});
	};
	const handleOk = () => {
		dispatch(hidewModalRedux());
	};

	const handleDate = (date, dateString) => {
		setFecha1(dateString);
	};

	const onChange = (e, v) => {
		console.log(e);
	};

	const onFinish = async (values) => {
		setIsDisabled(true);
		let body = {
			mortgageId: mortgageId,
			body: values.NotesPostpone,
			reason: values.motivoPostpone,
			subreason: values.subreason,
			closeAllTask: values.closeAllTask,
			type: 'postpone',
			date:
				typeof values.date === 'undefined'
					? dayjs().format('YYYY-MM-DD')
					: dayjs(values.date).format('YYYY-MM-DD')
		};
		handleApiPostpone(body);
	};

	const onFinishReminder = async (values) => {
		let body = {
			mortgageId: mortgageId,
			body: values.OtherNotesPostpone,
			reason: values.MotivoTextPostpone,
			type: 'reminder',
			date:
				typeof values.date === 'undefined'
					? moment().format('YYYY-MM-DD')
					: moment(values.date).format('YYYY-MM-DD')
		};

		handleApiPostpone(body);
	};

	const handleCancel = () => {
		dispatch(hidewModalRedux());
	};

	return (
		<Modal
			className="gb-modal-style-common"
			cancelButtonProps={{ className: 'button-secundary-gibobs' }}
			okButtonProps={{ className: 'button-primari-gibobs' }}
			title="SNOOZE"
			open={showModal}
			centered
			okText={'Guardar'}
			footer={null}
			width="800px"
			closable={true}
			onCancel={() => handleCancel()}>
			<div>
				<Tabs defaultActiveKey="1" onChange={onChange}>
					<TabPane tab="Posponer" key="1">
						<Form name="snooze" onFinish={onFinish}>
							<Row gutter={24}>
								<Col span={24}>
									<Row gutter={24}>
										<Col span={12}>
											<label>
												{' '}
												La operacion se <strong>pospone</strong> hasta:
											</label>
										</Col>
										<Col span={12}>
											<Form.Item
												label=""
												noStyle
												name="date"
												//style={{}}
												labelAlign="right">
												<DatePicker
													style={{ width: '100%' }}
													onChange={(date, dateString) => handleDate(date, dateString)}
													defaultValue={dayjs()}
													format={'DD/MM/YYYY'}
													placeholder={'Fecha'}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24} style={{ paddingTop: 20 }}>
										<Col span={12}>
											<strong> MOTIVO por el cual se POSPONE </strong>
										</Col>
										<Col span={12}>
											<Form.Item
												//label="Fecha Nacimiento"
												name="motivoPostpone"
												rules={[
													{
														required: enable2,
														message: 'Campo Motivo no puede quedar vacio!'
													}
												]}>
												<Select
													style={{ width: '100%' }}
													placeholder="Seleccione motivo"
													onChange={(e) => setSelectmotivo1(e)}
													options={promoter === true ? optionsPromoter : optionsNonPromoter}
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={12}>
											<strong> SUBMOTIVO </strong>
										</Col>
										<Col span={12}>
											<Form.Item name="subreason">
												{selectmotivo1.includes('Esperando reconocimiento') ? (
													<Select
														placeholder="Seleccione banco"
														name="subreason"
														showSearch
														filterOption={(input, option) =>
															option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														style={{ width: '100%' }}>
														{banksList
															? banksList.map((nameBank) => {
																	return (
																		<Option key={nameBank.id} value={nameBank.name}>
																			{nameBank.name}
																		</Option>
																	);
															  })
															: null}
													</Select>
												) : (
													<Input placeholder="Ingresar submotivo" />
												)}
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={12}>
											<strong>¿Cerrar las tareas de esta operación?</strong>
										</Col>
										<Col span={12}>
											<Form.Item
												name="closeAllTask"
												rules={[
													{
														required: true,
														message:
															'Debes seleccionar si deseas cerrar las tareas asociadas a esta operación'
													}
												]}>
												<Select placeholder="Seleccione">
													<Option value={true}>Si</Option>
													<Option value={false}>No</Option>
												</Select>
											</Form.Item>
										</Col>
									</Row>

									<Row gutter={24}>
										<Col span={12}>
											<strong>OTRAS NOTAS</strong>
										</Col>
										<Col span={12}>
											<Form.Item
												name="NotesPostpone"
												rules={[
													{
														required: enable2,
														message: 'Campo Otras NOTAS no puede quedar vacio!'
													}
												]}>
												<Input style={{ width: '100%' }} placeholder="Ingresar Notas" />
											</Form.Item>
										</Col>
									</Row>

									{selectmotivo1 === 'Necesita vender su vivienda previamente' && (
										<Row style={{ backgroundColor: '#B8DEEF4D', padding: 16 }}>
											<Col span={2}>
												<InfoCircleOutlined style={{ fontSize: 24, color: '#2F4858' }} />
											</Col>
											<Col span={22}>
												<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 500 }}>
													Recuerda que, si tu cliente ha terminado de pagar la hipoteca de la
													vivienda, debe cancelarla registralmente para venderla. En Gibobs podemos
													gestionarlo.
													<br />
													<br />
													Avisa a Belén González de Producto en caso de que el cliente quiera
													realizar la
													<span style={{ fontWeight: 600 }}> cancelación con Gibobs.</span>
												</Text>
											</Col>
										</Row>
									)}
								</Col>
							</Row>
							<Row
								span={24}
								type="flex"
								align="right"
								style={{
									float: 'right',
									marginTop: selectmotivo1 === 'Necesita vender su vivienda previamente' ? 40 : 20
								}}>
								<Col span={12} type="flex" align="right">
									<Form.Item type="flex" align="right">
										<Button
											style={{ marginRight: 20, width: 120 }}
											type="dashed"
											htmlType="submit"
											className="button-secundary-gibobs"
											//disabled={!(validateEmptyField(name) && validateEmptyField(surName))}
											onClick={() => handleCancel()}>
											Salir
										</Button>
									</Form.Item>
								</Col>

								<Col span={12} type="flex" align="right">
									<Form.Item>
										<Button
											type="primary"
											htmlType="submit"
											className="button-primari-gibobs"
											style={{ width: 120 }}
											disabled={isDisabled}
											//onClick={()=>onFinish()}
										>
											Guardar
										</Button>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</TabPane>

					{/* <TabPane tab="Recordatorio" key="2">
            <Form name="snooze" onFinish={onFinishReminder}>
              <Row gutter={24}>
                <Col span={24}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <label>
                        La operacion se pospone, pero <br /> quiero configurar un{" "}
                        <strong>recordatorio</strong>
                        <br /> con fecha pospone
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        //label="Fecha"
                        name="dateReminder"
                        labelAlign="right"
                        rules={[
                          {
                            required: false,
                            message: "Debe Ingresar Fecha",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          // disabled={enable2}
                          onChange={(date, dateString) =>
                            handleDate(date, dateString)
                          }
                          format={"DD/MM/YYYY"}
                          placeholder={"Fecha"}
                          defaultValue={moment()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ paddingTop: 20 }}>
                    <Col
                      // style={{
                      //   paddingLeft: 22,
                      //   marginTop: -25,
                      //   paddingRight: 30,
                      // }}
                      span={12}
                    >
                      <strong>MOTIVO</strong>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="MotivoTextPostpone" noStyle>
                        <Input
                          style={{ width: "100%" }}
                          onChange={(e) => setMotivetext(e)}
                          placeholder="Ingresar Motivo"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ paddingTop: 20 }}>
                    <Col span={12}>
                      <strong>OTRAS NOTAS</strong>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="OtherNotesPostpone" noStyle>
                        <Input
                          onChange={(e) => setNotas(e)}
                          //disabled={enable2}
                          placeholder="Igresar Notas"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24} type="flex" align="right" style={{ paddingTop: 20, float:"right" }}>
                    <Col type="flex" align="right">
                      <Form.Item type="flex" align="right">
                        <Button
                          // style={{ marginLeft: 250, marginRight: 20 }}
                          type="dashed"
                          htmlType="submit"
                          className="button-secundary-gibobs"
                          //disabled={!(validateEmptyField(name) && validateEmptyField(surName))}
                          onClick={() => handleCancel()}
                        >
                          Salir
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col type="flex" align="right">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-primari-gibobs"
                        //disabled={!(validateEmptyField(name) && validateEmptyField(surName))}
                        //onClick={(e)=>onFinishReminder(e)}
                        >
                          Guardar
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </TabPane> */}
				</Tabs>
			</div>
		</Modal>
	);
};

export default SnoozeModal;
