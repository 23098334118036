import React, { useEffect, useState } from 'react';
import { Typography, Layout, Tabs, Row, Col, Select, Modal, Form, Input, Checkbox } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';

const { Text } = Typography;
const { TextArea } = Input;

type ModalStatusProps = {
	request: any;
	modalComments: any;
	modalChecks: any;
	modalFinish: any;
	modalCancel: any;
	dataOperation: any;
	setModalComments: any;
	setModalChecks: any;
	setModalFinish: any;
	setModalCancel: any;
	sendinComment: any;
};

const ModalStatus = ({
	request,
	modalComments,
	modalChecks,
	modalFinish,
	modalCancel,
	dataOperation,
	setModalComments,
	setModalChecks,
	setModalFinish,
	setModalCancel,
	sendinComment
}: ModalStatusProps): React.JSX.Element => {
	const [form] = Form.useForm();

	const renderDataModal = () => {
		const discountedFee = _.find(dataOperation, function (data) {
			return data === 'recommendedResult.discountedFee';
		});
		const unsubsidizedFee = _.find(dataOperation, function (data) {
			return data === 'recommendedResult.unsubsidizedFee';
		});
		const priceProfileRecommender = _.find(dataOperation, function (data) {
			return data === 'recommendedResult.priceProfileRecommender';
		});
		const bonifiedType = _.find(dataOperation, function (data) {
			return data === 'recommendedResult.bonifiedType';
		});
		const notBonifiedType = _.find(dataOperation, function (data) {
			return data === 'recommendedResult.notBonifiedType';
		});
		const monthlyFixedNetIncome = _.find(dataOperation, function (data) {
			return data === 'owner1.incomesWealth.monthlyFixedNetIncome';
		});
		const monthlyVariableNetIncome = _.find(dataOperation, function (data) {
			return data === 'owner1.incomesWealth.monthlyVariableNetIncome';
		});
		const monthlyFixedNetIncome2 = _.find(dataOperation, function (data) {
			return data === 'owner2.incomesWealth.monthlyFixedNetIncome';
		});
		const monthlyVariableNetIncome2 = _.find(dataOperation, function (data) {
			return data === 'owner2.incomesWealth.monthlyVariableNetIncome';
		});

		return (
			<>
				<div>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
						Para poder finalizar el RECO debes rellenar estos datos.
					</Text>
				</div>
				{discountedFee !== undefined &&
					unsubsidizedFee !== undefined &&
					priceProfileRecommender !== undefined &&
					bonifiedType !== undefined &&
					notBonifiedType !== undefined && (
						<div style={{ marginTop: 16 }}>
							<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Resultado recomendador
							</Text>
						</div>
					)}
				<div style={{ marginTop: 16 }}>
					{priceProfileRecommender && (
						<div>
							<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Perfil de precio
							</Text>
						</div>
					)}
					{bonifiedType && (
						<div>
							<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Tipo bonificado
							</Text>
						</div>
					)}

					{discountedFee && (
						<div>
							<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Cuota bonificada
							</Text>
						</div>
					)}
					{notBonifiedType && (
						<div>
							<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Tipo no bonificado
							</Text>
						</div>
					)}
					{unsubsidizedFee && (
						<div>
							<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Cuota no bonificada
							</Text>
						</div>
					)}
				</div>
				{((monthlyFixedNetIncome !== undefined && monthlyVariableNetIncome !== undefined) ||
					(monthlyFixedNetIncome2 !== undefined && monthlyVariableNetIncome2 !== undefined)) && (
					<div style={{ marginTop: 16 }}>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400 }}>
							{' '}
							Recomendador resultado Toolkit
						</Text>
					</div>
				)}
				{monthlyFixedNetIncome && (
					<div style={{ marginTop: 8 }}>
						<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							Ingresos netos/mes fijos T1
						</Text>
					</div>
				)}
				{monthlyVariableNetIncome && (
					<div>
						<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							Ingresos netos/mes variables T1
						</Text>
					</div>
				)}
				{monthlyFixedNetIncome2 && (
					<div style={{ marginTop: 8 }}>
						<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							Ingresos netos/mes fijos T2
						</Text>
					</div>
				)}
				{monthlyVariableNetIncome2 && (
					<div>
						<CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 16 }}></CloseCircleOutlined>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							Ingresos netos/mes variables T2
						</Text>
					</div>
				)}
			</>
		);
	};

	return (
		<>
			<Modal
				className="ModalCommon ModalLastCom"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '45%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '45%', height: 40 }
				}}
				open={modalComments}
				title={'Cambio de estado a “En curso”'}
				okText={'Pasar a En Curso'}
				cancelText={'Cancelar'}
				destroyOnClose={true}
				onCancel={() => {
					setModalComments(false);
					//window.location.reload();
				}}
				width={520}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							sendinComment(values, 'active', request.id);
							setModalComments(false);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Form form={form} layout="vertical" style={{ marginBottom: -15 }}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							Vas a pasar el RECO a
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}> “En curso”</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							{`y ${request.reco} será el responsable de la elaboración.`}
						</Text>
					</div>
					<div style={{ marginTop: 24, width: 420 }}>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							Confirma el cambio. Podrás ver esta operación en el apartado
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}> “En curso”</Text>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
						<Form.Item
							name="comments"
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>Comentarios</Text>
							}
							rules={[{ required: true, message: 'Necesitas escribir el motivo' }]}
							style={{ marginRight: 32, width: '100%', height: 80 }}>
							<TextArea
								style={{ width: '100%', height: 80 }}
								placeholder={'Explicación del motivo...'}
							/>
						</Form.Item>
					</div>
				</Form>
			</Modal>

			<Modal
				className="ModalCommon ModalLastCom"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '45%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '45%', height: 40 }
				}}
				open={modalChecks}
				title={'Cambio de estado a “Bloqueada”'}
				okText={'Pasar a Bloqueada'}
				cancelText={'Cancelar'}
				destroyOnClose={true}
				onCancel={() => {
					setModalChecks(false);
					//window.location.reload();
				}}
				width={635}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							sendinComment(values, 'blocked', request.id);
							setModalChecks(false);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Form form={form} layout="vertical" style={{ marginBottom: -15 }}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							Vas a pasar el RECO a
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}> “Bloqueada”</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							{' '}
							confirma el cambio.
						</Text>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
						<Form.Item
							name="checkData"
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									Motivo de bloqueo
								</Text>
							}
							rules={[{ required: true }]}
							style={{ marginRight: 32, width: '100%', height: 80 }}>
							<Checkbox.Group style={{ width: '100%' }}>
								<Row>
									<Col span={12}>
										<Checkbox
											value="data"
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											Faltan datos
										</Checkbox>
									</Col>
									<Col span={12}>
										<Checkbox
											value="lopd"
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											Bloqueo LOPD
										</Checkbox>
									</Col>
									<Col span={12} style={{ marginTop: 16 }}>
										<Checkbox
											value="docs"
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											Falta documentación
										</Checkbox>
									</Col>
									<Col span={12} style={{ marginTop: 16 }}>
										<Checkbox
											value="correction_data"
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											Corrección de datos en sucursal
										</Checkbox>
									</Col>
									<Col span={12} style={{ marginTop: 16 }}>
										<Checkbox
											value="scl"
											style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
											SCL
										</Checkbox>
									</Col>
								</Row>
							</Checkbox.Group>
						</Form.Item>
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 44 }}>
						<Form.Item
							name="comments"
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									Explica el por qué
								</Text>
							}
							rules={[{ required: true }]}
							style={{ marginRight: 32, width: '100%', height: 80 }}>
							<TextArea
								style={{ width: '100%', height: 80 }}
								placeholder={'Explicación del motivo...'}
							/>
						</Form.Item>
					</div>
				</Form>
			</Modal>

			<Modal
				className="ModalCommon ModalLastCom"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '45%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '45%', height: 40 }
				}}
				open={modalFinish}
				title={dataOperation.length > 0 ? 'Faltan datos' : 'Cambio de estado a “Finalizada”'} //todo si faltan datos si no Cambio de estado a “Finalizada”
				okText={dataOperation.length > 0 ? 'Ir a editar datos' : 'Finalizar'} // todo si faltan datos, si no Ir a editar datos
				cancelText={'Cancelar'}
				destroyOnClose={true}
				onCancel={() => {
					setModalFinish(false);
					//window.location.reload();
				}}
				width={520}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							if (dataOperation.length > 0) {
								setModalFinish(false);
							} else {
								sendinComment(values, 'done', request.id);
								setModalFinish(false);
							}
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				{dataOperation.length > 0 ? (
					renderDataModal()
				) : (
					<Form form={form} layout="vertical" style={{ marginBottom: -15 }}>
						<div>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								Vas a pasar el RECO a
							</Text>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}>
								{' '}
								“Finalizada”,
							</Text>
							<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
								{' '}
								Confirma el cambio.
							</Text>
						</div>

						<div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
							<Form.Item
								name="comments"
								label={
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										Comentarios para finalización
									</Text>
								}
								rules={[{ required: true }]}
								style={{ marginRight: 32, width: '100%', height: 80 }}>
								<TextArea
									style={{ width: '100%', height: 80 }}
									placeholder={'Explicación del motivo...'}
								/>
							</Form.Item>
						</div>
					</Form>
				)}
			</Modal>

			<Modal
				className="ModalCommon ModalLastCom"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '45%', height: 40 }
				}}
				okButtonProps={{
					className: 'button-primari-gibobs',
					style: { width: '45%', height: 40 }
				}}
				open={modalCancel}
				title={'Cambio de estado a “Cancelado”'}
				okText={'Pasar a Cancelada'}
				cancelText={'Cancelar'}
				destroyOnClose={true}
				onCancel={() => {
					setModalCancel(false);
					//window.location.reload();
				}}
				width={520}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							sendinComment(values, 'canceled', request.id);
							setModalCancel(false);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}>
				<Form form={form} layout="vertical" style={{ marginBottom: -15 }}>
					<div>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}>
							Vas a pasar el RECO a
						</Text>
						<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 600 }}> “Cancelada”.</Text>
					</div>
					<Text style={{ color: '#748EA0', fontSize: 14, fontWeight: 400 }}> ¿Estás seguro? </Text>

					<div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
						<Form.Item
							name="comments"
							label={
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>Comentarios</Text>
							}
							rules={[{ required: true }]}
							style={{ marginRight: 32, width: '100%', height: 80 }}>
							<TextArea
								style={{ width: '100%', height: 80 }}
								placeholder={'Explicación del motivo...'}
							/>
						</Form.Item>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default ModalStatus;
