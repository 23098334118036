import React, { useState, useEffect } from 'react';
import { Button, Typography, Col, Row, Input, Modal, Form, Select, Spin, notification } from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import _, { truncate } from 'lodash';
import { InputDate, InputNumber, ProvinceSelector } from 'afrodita';
import TextArea from 'antd/lib/input/TextArea';

const { Text } = Typography;
const { Option } = Select;

const TablUci = ({ mortgageId, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [provinces, setProvinces] = useState(undefined);
	const [municipes, setMunicipes] = useState(undefined);
	const [otherDatasAll, setOtherDatasAll] = useState(undefined);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState(undefined);
	let timeout;
	let currentValue;
	const location = useLocation();
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const municipeBuy =
		mortgage && mortgage.subrogation !== undefined
			? mortgage.subrogation.population !== undefined
				? `(${mortgage.subrogation.population})`
				: ''
			: mortgage.mortgage.population !== undefined
			? `(${mortgage.mortgage.population})`
			: '';
	const [emptyFields, setEmptyFields] = useState([]);

	useEffect(() => {
		getProvinces();
		getMunicipes();
		getOtherData();
	}, []);

	useEffect(() => {
		if (mortgage && provinces && otherDatasAll) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(
					([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
				)
				.map(([fieldName]) => fieldName);
			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage, provinces, otherDatasAll]);

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const getOtherData = () => {
		axios.get(`${env.api.url}/banks/uci/data/jsonOther`).then((response) => {
			setOtherDatasAll(response.data.data);
		});
	};
	const getProvinces = () => {
		axios.get(`${env.api.url}/banks/uci/data/jsonProvinces`).then((response) => {
			setProvinces(response.data.data.dataProvinces);
		});
	};
	const getMunicipes = () => {
		axios.get(`${env.api.url}/banks/uci/data/municipes`).then((response) => {
			setMunicipes(response.data.data.municipes);
		});
	};

	const search = (value) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		currentValue = value;

		const searchData = () => {
			axios.get(`${env.api.url}/banks/uci/data/municipes?search=${value}`).then((response) => {
				if (currentValue === value) {
					setData(response.data.data.municipes);
				}
			});
		};
		timeout = setTimeout(searchData, 300);
	};
	const handleSearch = (value) => {
		if (value) {
			search(value);
		} else {
			setData([]);
		}
	};
	{
		/* {municipes?.map((province) => (
										<Option key={province.CMUN} value={province.CMUN}>
											{province.name}
										</Option>
									))} */
	}
	const options = data.map((d) => (
		<Option key={d.code} value={d.code}>
			{d.name}
		</Option>
	));

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= 1; index++) {
			arrayform.push(
				<>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: index === 1 ? 32 : 24
							}}>
							{'Titular ' + index}
						</Text>
					</Row>
					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'nifType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de documento'}
									</Text>
								}
								className={emptyFields.includes('nifType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll.dataNIF.nifType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'jobType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de trabajo'}
									</Text>
								}
								className={emptyFields.includes('jobType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll.dataTypeworks.jobType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'schedule'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Horario de contacto'}
									</Text>
								}>
								<Input
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'numberOfLoans'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Número de prestamos'}
									</Text>
								}
								className={emptyFields.includes('numberOfLoans') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber
									className="inputLinkages"
									id={`numberOfLoans${index}`}
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									placeholder={0}
									suffix={' '}
									gbType="number"
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'loanAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe de prestamos'}
									</Text>
								}
								className={emptyFields.includes('loanAmount') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber
									className="inputLinkages"
									id={`loanAmount${index}`}
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									placeholder={0}
									suffix={' '}
									gbType="currency"
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'haveHouse'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Vivienda propia'}
									</Text>
								}
								rules={[{ required: false, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'haveHouseAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe de vivienda propia'}
									</Text>
								}>
								<InputNumber
									id={`numberOfLoans${index}`}
									placeholder={0}
									gbDecimalType="integer"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									gbType="currency"
								/>
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'pendingMortgage'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Hipoteca Pendiente'}
									</Text>
								}
								className={emptyFields.includes('pendingMortgage') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
									onChange={handleFormChange}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'pendingAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe hipoteca pendiente'}
									</Text>
								}>
								<InputNumber
									id={`numberOfLoans${index}`}
									placeholder={0}
									gbDecimalType="integer"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									gbType="currency"
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'changeHouse'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Cambio de casa'}
									</Text>
								}>
								<Select
									showSearch
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'sellingHouse'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Vende vivienda'}
									</Text>
								}>
								<Select
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
									onChange={handleFormChange}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'provinceSell'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Provincia de venta`}
									</Text>
								}>
								<ProvinceSelector
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'municipeSell'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Municipio de venta`}
									</Text>
								}>
								<Select
									autoClearSearchValue={true}
									showSearch
									value={value !== '' ? value : ''}
									placeholder="Escribe para buscar..."
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									onSearch={(value) => {
										handleSearch(value);
									}}
									notFoundContent={null}>
									{options}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'localitySell'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Localidad de venta`}
									</Text>
								}>
								<Input style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'valueMortgageSelling'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Valor del inmueble en venta'}
									</Text>
								}>
								<InputNumber
									id={`numberOfLoans${index}`}
									placeholder={0}
									gbDecimalType="integer"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									gbType="currency"
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'amountMortgagePending'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe hipoteca pendiente vende'}
									</Text>
								}>
								<InputNumber
									id={`numberOfLoans${index}`}
									placeholder={0}
									gbDecimalType="integer"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									gbType="currency"
								/>
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	function removeAccents(text) {
		const accentsMap = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U'
		};

		return text.replace(/[áéíóúÁÉÍÓÚ]/g, function (matched) {
			return accentsMap[matched];
		});
	}

	const onFinish = (values) => {
		setLoading(true);
		const obj = { ...values };

		if (obj.provinceBuy !== undefined) {
			obj.provinceBuy = {
				code: provinces.find(
					(data) => data.description === removeAccents(obj.provinceBuy.toUpperCase())
				).codProvincia,
				name: removeAccents(obj.provinceBuy.toUpperCase())
			};
		} else {
			delete obj.provinceBuy;
		}
		if (obj.provinceSell !== undefined) {
			obj.provinceSell = {
				code: provinces.find(
					(data) => data.description === removeAccents(obj.provinceSell.toUpperCase())
				).codProvincia,
				name: removeAccents(obj.provinceSell.toUpperCase())
			};
		} else {
			delete obj.provinceSell;
		}
		if (obj.provinceDg !== undefined) {
			obj.provinceDg = {
				code: provinces.find(
					(data) => data.description === removeAccents(obj.provinceDg.toUpperCase())
				).codProvincia,
				name: removeAccents(obj.provinceDg.toUpperCase())
			};
		} else {
			delete obj.provinceDg;
		}
		if (obj.municipeDg !== undefined) {
			obj.municipeDg = {
				code: obj.municipeDg,
				name: data.filter((data) => data.code === obj.municipeDg)[0].name
			};
		} else {
			delete obj.municipeDg;
		}
		if (obj.municipeBuy !== undefined) {
			obj.municipeBuy = {
				code: obj.municipeBuy,
				name: data.filter((data) => data.code === obj.municipeBuy)[0].name
			};
		} else {
			delete obj.municipeBuy;
		}
		if (obj.municipeSell !== undefined) {
			obj.municipeSell = {
				code: obj.municipeSell,
				name: data.filter((data) => data.code === obj.municipeSell)[0].name
			};
		} else {
			delete obj.municipeSell;
		}
		if (obj.dateSign !== undefined) {
			obj.dateSign = {
				year: moment(obj.dateSign, ['DD-MM-YYYY']).format('YYYY'),
				month: moment(obj.dateSign, ['DD-MM-YYYY']).format('MM'),
				day: moment(obj.dateSign, ['DD-MM-YYYY']).format('DD')
			};
		} else {
			delete obj.dateSign;
		}

		obj.bankRequestId = bankRequestId;
		obj.operationId = mortgageId;

		if (
			obj.haveHouseAmount ||
			obj.amountMortgagePending ||
			obj.dgAmount ||
			obj.pendingAmount ||
			obj.numberOfLoans ||
			obj.loanAmount ||
			obj.amountReform ||
			obj.valueMortgageSelling
		) {
			obj.haveHouseAmount = obj.haveHouseAmount?.toString();
			obj.amountMortgagePending = obj.amountMortgagePending?.toString();
			obj.dgAmount = obj.dgAmount?.toString();
			obj.pendingAmount = obj.pendingAmount?.toString();
			obj.numberOfLoans = obj.numberOfLoans?.toString();
			obj.loanAmount = obj.loanAmount?.toString();
			obj.amountReform = obj.amountReform?.toString();
			obj.valueMortgageSelling = obj.valueMortgageSelling?.toString();
		}

		for (let clave in values) {
			if (values[clave] === undefined) {
				delete obj[clave];
			}
		}

		axios
			.post(`${env.api.url}/banks/uci/create-uci-bankrequest`, obj, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				setLoading(false);

				if (response.data.errorCode === 'UCI_CLIENT_FAILED') {
					notification.error({
						message: 'Se ha producido un error al enviar a UCI',
						description: response.data.data.moreInformation,
						duration: 0
					});

					setLoading(false);
				} else if (response.data.data === 'UCI_BANKREQUEST_PENDING') {
					notification.error({
						message: 'Un momento',
						description: `Hay un envio en curso por favor espere`,
						duration: 0
					});
					setLoading(false);
				} else if (response.data.errorCode === 'UCI_CREATE_ASSESMENT_FAILED') {
					notification.error({
						message: 'Envio fallido',
						description: `Intentalo otra vez.`,
						duration: 0
					});
					setLoading(false);
				} else if (response.data.code === 500) {
					notification.error({
						message: 'Envio fallido',
						description: `Algo se ha roto en el API de UCI (Hablar con Fernando)`,
						duration: 0
					});
					setLoading(false);
				} else {
					notification.success({
						message: 'Envio correcto',
						description: 'El envio de datos a UCI se ha realizado correctamente'
					});
					setLoading(false);
					navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
					window.location.reload();
				}
			})
			.catch((response) => {
				setLoading(false);
			});
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};

	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const renderTypeWork = (id) => {
		let typeWork = [
			{ code: 'I', name: 'EMPLOYEE_FIXED' },
			{ code: 'A', name: 'FREELANCER' },
			{ code: 'P', name: 'PENSIONER' },
			{ code: 'T', name: 'EMPLOYEE_TEMPORAL' }
		];

		if (
			id === 'EMPLOYEE_FIXED' ||
			id === 'FREELANCER' ||
			id === 'PENSIONER' ||
			id === 'EMPLOYEE_TEMPORAL'
		) {
			return typeWork.find((t) => t.name === id).code;
		} else {
			return undefined;
		}
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};

	const renderProvince = (province) => {
		const tmpProvince = quitarAcentos(province);
		const city = _.find(provinces, function (o) {
			return o.description === tmpProvince.toUpperCase();
		});

		return city?.codProvincia;
	};

	const quitarAcentos = (cadena) => {
		const acentos = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U'
		};
		return cadena
			.split('')
			.map((letra) => acentos[letra] || letra)
			.join('')
			.toString();
	};

	if (provinces && otherDatasAll && loading === false) {
		return (
			<>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
					Datos de la oferta solicitada
				</Text>
				<Form
					form={form}
					layout="vertical"
					name="sendMessageForm"
					className="gb-form-offers-new"
					style={{ width: '100%' }}
					initialValues={{
						provinceBuy:
							mortgage.subrogation !== undefined
								? mortgage.subrogation.province === 'Lleida'
									? 'Lerida'
									: mortgage.subrogation.province
								: mortgage.mortgage.province === 'Lleida'
								? 'Lerida'
								: mortgage.mortgage.province,
						jobType:
							mortgage['owner1'].activity !== undefined
								? renderTypeWork(mortgage['owner1'].activity)
								: '',
						amount:
							type.subtype === 'mortgage' ? bankRequest?.amount : mortgage?.subrogation?.amount,
						ccv: mortgage.mortgage.maxBudget,
						term: bankRequest.months / 12,
						decidedHouse:
							mortgage.subrogation !== undefined
								? 'S'
								: mortgage.mortgage.chooseProperty === 'yes'
								? 'S'
								: 'N',
						purposeHousing: mortgage.mortgage.houseType === 'second' ? 'SR' : 'VH',
						typeHousing: mortgage.mortgage.propertyType === 'newConstruction' ? 'VN' : 'VU',
						otherIncomes1: mortgage['owner1'].bonus,
						otherIncomes2: mortgage['owner2'] !== undefined ? mortgage['owner2'].bonus : 0,
						loanPayment1:
							(mortgage['owner1'].loanPayment ?? 0) + (mortgage['owner1'].mortgagePayment ?? 0),
						loanPayment2:
							mortgage['owner2'] !== undefined
								? mortgage['owner2'].loanPayment + mortgage['owner2'].mortgagePayment
								: 0,
						paymentPension1: mortgage['owner1'].pensionPayment,
						paymentPension2:
							mortgage['owner2'] !== undefined ? mortgage['owner2'].pensionPayment : 0,
						nifType:
							mortgage['owner1'].idDocumentType === 'ID'
								? 'D'
								: mortgage['owner1'].idDocumentType === 'FOREIGN_ID'
								? 'N'
								: undefined,
						numberOfLoans: mortgage['owner1'].paymentObligations?.activeLoan?.length || '0',
						loanAmount: mortgage['owner1'].loanPayment || '0',
						haveHouse: mortgage['owner1'].canHavePaidOwnedHouses
							? 'S'
							: mortgage['owner1'].mortgagePaymentBoolean
							? 'S'
							: undefined,
						haveHouseAmount:
							mortgage['owner1'].incomesWealth?.ownedHouses?.[0]?.estimatedValue !== undefined
								? mortgage['owner1'].incomesWealth.ownedHouses[0].estimatedValue
								: mortgage['owner1'].paymentObligations?.mortgage?.[0]?.purchasePrice !== undefined
								? mortgage['owner1'].paymentObligations.mortgage[0].purchasePrice
								: undefined,
						pendingMortgage: mortgage['owner1'].mortgagePaymentBoolean ? 'S' : undefined,
						pendingAmount:
							mortgage['owner1'].paymentObligations &&
							mortgage['owner1'].paymentObligations?.mortgage &&
							mortgage['owner1'].paymentObligations?.mortgage[0]
								? mortgage['owner1'].paymentObligations?.mortgage[0].pendingCapital
								: undefined,
						notes: mortgage.otherDataAdditional?.summary || undefined
					}}
					onChange={handleFormChange}
					onFinish={onFinish}>
					<Row gutter={[32, 4]} style={{ marginTop: 24 }}>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'operationType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de operación'}
									</Text>
								}
								className={emptyFields.includes('operationType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll.dataOperationType.operationType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'decidedHouse'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Compra decidida'}
									</Text>
								}
								className={emptyFields.includes('decidedHouse') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
									onChange={handleFormChange}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'provinceBuy'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Provincia de compra`}
									</Text>
								}
								className={emptyFields.includes('provinceBuy') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<ProvinceSelector
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'municipeBuy'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Municipio de compra ${municipeBuy}`}
									</Text>
								}>
								<Select
									autoClearSearchValue={true}
									showSearch
									value={value !== '' ? value : ''}
									placeholder="Escribe para buscar..."
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									onSearch={(value) => {
										handleSearch(value);
									}}
									notFoundContent={null}>
									{options}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'nearBuy'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Compra próxima'}
									</Text>
								}>
								<Select
									showSearch
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'dateSign'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de firma'}
									</Text>
								}>
								<InputDate style={{ width: '100%', borderRadius: 4 }} type="date" />
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'reform'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Reforma'}
									</Text>
								}>
								<Select
									showSearch
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									<Option value="S">Si</Option>
									<Option value="N">No</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'amountReform'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe presupuesto de reforma'}
									</Text>
								}>
								<InputNumber
									id={`amountReform`}
									placeholder={0}
									gbDecimalType="integer"
									gbType="currency"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'dgAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe Dg'}
									</Text>
								}>
								<InputNumber
									id={`dgAmount`}
									placeholder={0}
									gbDecimalType="integer"
									style={{ height: '40px', borderRadius: 4, width: '100%' }}
									gbType="currency"
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'provinceDg'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Provincia DG`}
									</Text>
								}>
								<ProvinceSelector
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} md={8} sm={8} lg={8} xl={6} xxl={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'municipeDg'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Municipio Dg`}
									</Text>
								}>
								<Select
									autoClearSearchValue={true}
									showSearch
									value={value !== '' ? value : ''}
									placeholder="Escribe para buscar..."
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									onSearch={(value) => {
										handleSearch(value);
									}}
									notFoundContent={null}>
									{options}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{renderForm()}

					<Row>
						<Col xs={24} md={24} sm={24} lg={24} xl={24} xxl={24}>
							<Form.Item
								style={{ width: '100%' }}
								name={'notes'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{`Notas`}</Text>
								}>
								<TextArea
									style={{
										borderColor: '#c0daee',
										borderRadius: '4px',
										width: '100%',
										height: '84px'
									}}
									placeholder={'Notas'}></TextArea>
							</Form.Item>
						</Col>
					</Row>

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => cancelSend()}
							type="dashed">
							Atras
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
					open={visibleCancel}
					title={
						<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
							{'Cancelar envío'}
						</Text>
					}
					okText={'Aceptar'}
					cancelText={'Cancelar'}
					width={404}
					onCancel={handleCancel}
					onOk={handleOk2}>
					<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
						{'¿Aceptas la cancelación de del envio a UCI?'}
					</Text>
				</Modal>
			</>
		);
	} else {
		return (
			<>
				<div style={{ textAlign: 'center', height: '40vh' }}>
					<Spin style={{ position: 'absolute', top: '30%' }} size="large" />
				</div>
				<div style={{ textAlign: 'center', height: '80vh' }}>
					<Text style={{ marginTop: 34, fontSize: 14, fontWeight: 500 }}>
						Por favor, no cierre ni recargue la pagina, puede tardar hasta 3 minutos...
					</Text>
				</div>
			</>
		);
	}
};
export default TablUci;
