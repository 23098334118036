import React, { useState } from 'react';
import moment from 'moment';

const WeeksCalendar = ({ taskDays, days, index, allTask, currentDate, setCurrentDate }) => {
	const filterTask = (days, type) => {
		if (type !== undefined) {
			return allTask.filter(
				(data) => moment(data.duedDate).format('DD/MM/YYYY') === days && type.includes(data.type)
			);
		} else {
			return allTask.filter((data) => moment(data.duedDate).format('DD/MM/YYYY') === days);
		}
	};
	return (
		<div className="calendar-card">
			{taskDays.map((data, i) => {
				const currentDay =
					moment(days && days[index])
						.add(i, 'days')
						.format('YYYY/MM/DD') === moment(currentDate).format('YYYY/MM/DD');
				const taskDay = moment(days && days[index])
					.add(i, 'days')
					.format('DD/MM/YYYY');
				// if (i !== 5 && i !== 6) {
				return (
					<div
						key={`${data.key}-${i}`}
						className="card-daily"
						style={{
							background: currentDay
								? '#EFFCFC'
								: (i == 6 || i == 5 || i == 12 || i == 13) && '#F1F7F8',
							border: currentDay && '1px solid #02C3CD',
							cursor: 'pointer'
						}}
						onClick={() => setCurrentDate(moment(days && days[index]).add(i, 'days'))}>
						<div>
							{moment(days && days[index])
								.add(i, 'days')
								.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
								? 'HOY'
								: moment(days && days[index])
										.add(i, 'days')
										.format('DD')}
						</div>
						<div className="text-card">
							<div className="title-card-daily">
								{
									filterTask(
										moment(days && days[index])
											.add(i, 'days')
											.format('DD/MM/YYYY')
									).length
								}
							</div>
							<div className="title-card-daily" style={{ fontWeight: 400, marginLeft: 4 }}>
								tareas
							</div>
						</div>
						<div
							style={{ display: 'flex', width: '100%', justifyContent: 'center', columnGap: 12 }}>
							{filterTask(taskDay, ['call', 'doc']).length !== 0 && (
								<div style={{ display: 'flex' }}>
									<div className="task-title">{filterTask(taskDay, ['call', 'doc']).length}</div>
									<img src={'/images/callIcon.svg'} alt="callIcon" />
								</div>
							)}
							{filterTask(taskDay, ['claimoffer', 'claimappraisal', 'comdoc', 'claim']).length !==
								0 && (
								<div style={{ display: 'flex' }}>
									<div className="task-title">
										{
											filterTask(taskDay, ['claimoffer', 'claimappraisal', 'comdoc', 'claim'])
												.length
										}
									</div>
									<img src={'/images/claimIcon.svg'} alt="claimIcon" />
								</div>
							)}
							{filterTask(taskDay, [
								'tag',
								'other',
								'followup',
								'firstcall',
								'customerqualification',
								'phpanalysis'
							]).length !== 0 && (
								<div style={{ display: 'flex' }}>
									<div className="task-title">
										{
											filterTask(taskDay, [
												'tag',
												'other',
												'followup',
												'firstcall',
												'customerqualification',
												'phpanalysis'
											]).length
										}
									</div>
									<img src={'/images/documentIcon.svg'} alt="documentIcon" />
								</div>
							)}
						</div>
					</div>
				);
				// }
			})}
		</div>
	);
};

export default WeeksCalendar;
